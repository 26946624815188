export default [
  {
    header: 'market.navigation.all',
  },
  {
    title: 'market.navigation.blog',
    icon: 'FileTextIcon',
    route: 'posts-index',
    resource: 'Auth',
    action: 'read',
  },
  {
    title: 'market.navigation.faq',
    icon: 'HelpCircleIcon',
    route: 'faq-index',
    resource: 'Auth',
    action: 'read',
  },
]
