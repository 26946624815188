export default [
  {
    header: 'market.navigation.admin.admin',
  },
  {
    title: 'market.navigation.admin.catalog',
    tagVariant: 'span',
    icon: 'ListIcon',
    children: [
      {
        title: 'market.navigation.admin.channels',
        route: 'admin:products-index',
        action: 'index',
        resource: 'admin:products',
      },
      {
        title: 'market.navigation.admin.categories',
        route: { name: 'admin:product-categories-index' },
        action: 'index',
        resource: 'admin:categories',
      },
      {
        title: 'market.navigation.admin.stickers',
        route: 'admin:stickers-index',
        action: 'index',
        resource: 'admin:stickers',
      },
      {
        title: 'market.navigation.admin.reviews',
        route: 'admin:reviews-index',
        action: 'index',
        resource: 'admin:reviews',
      },
    ],
  },
  {
    title: 'market.navigation.admin.projects',
    tagVariant: 'span',
    icon: 'BriefcaseIcon',
    children: [
      {
        title: 'market.navigation.admin.projects',
        route: 'admin:projects-index',
        action: 'index',
        resource: 'admin:projects',
      },
      {
        title: 'market.navigation.admin.tasks',
        route: 'admin:tasks-index',
        action: 'index',
        resource: 'admin:projects',
      },
    ],
  },
  {
    title: 'market.navigation.admin.transactions',
    icon: 'RepeatIcon',
    route: 'admin:transactions-index',
    action: 'index',
    resource: 'admin:transactions',
  },
  {
    title: 'market.navigation.admin.pages',
    icon: 'FileIcon',
    route: 'admin:pages-index',
    action: 'index',
    resource: 'admin:pages',
  },
  {
    title: 'market.navigation.admin.blog',
    tagVariant: 'span',
    icon: 'FileTextIcon',
    children: [
      {
        title: 'market.navigation.admin.categories',
        route: { name: 'admin:post-categories-index' },
        action: 'index',
        resource: 'admin:categories',
      },
      {
        title: 'market.navigation.admin.post_list',
        route: 'admin:posts-index',
        action: 'index',
        resource: 'admin:posts',
      },
    ],
  },
  {
    title: 'market.navigation.admin.faq',
    tagVariant: 'span',
    icon: 'HelpCircleIcon',
    children: [
      {
        title: 'market.navigation.admin.categories',
        route: { name: 'admin:faq-categories-index' },
        action: 'index',
        resource: 'admin:categories',
      },
      {
        title: 'market.navigation.admin.faq_list',
        route: 'admin:faq-index',
        action: 'index',
        resource: 'admin:faq',
      },
    ],
  },
  {
    title: 'market.navigation.admin.templates',
    icon: 'LayersIcon',
    route: 'admin:templates-index',
    action: 'index',
    resource: 'admin:templates',
  },
  {
    title: 'market.navigation.admin.users',
    tagVariant: 'span',
    icon: 'UsersIcon',
    children: [
      {
        title: 'market.navigation.admin.users_list',
        route: 'admin:users-index',
        action: 'index',
        resource: 'admin:users',
      },
      {
        title: 'market.navigation.admin.roles',
        route: 'admin:roles-index',
        action: 'index',
        resource: 'admin:roles',
      },
    ],
  },
  {
    title: 'market.navigation.admin.settings',
    tagVariant: 'span',
    icon: 'SettingsIcon',
    children: [
      {
        title: 'market.navigation.admin.settings',
        route: 'admin:settings',
        action: 'index',
        resource: 'admin:settings',
      },
      {
        title: 'market.navigation.admin.languages',
        route: 'admin:languages',
        action: 'index',
        resource: 'admin:languages',
      },
      {
        title: 'market.navigation.admin.translations',
        route: 'admin:translations',
        action: 'index',
        resource: 'admin:translations',
      },
      // {
      //   title: 'market.navigation.admin.navigations',
      //   route: 'admin:navigations-index',
      //   action: 'index',
      //   resource: 'admin:navigations',
      // },
    ],
  },
]
